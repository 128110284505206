import { useIntl } from "react-intl";

import SEO from "../../components/SEO";
import Header from "../../components/Header";
import Slider from "../../components/Slider";
import Footer from "../../components/Footer";

import Section from "../../components/Section";
import SectionBox from "../../components/SectionBox";

import Variables from "../../variables/variables.json";

// WITRYNA
import IndexSection1PL from "../../content/przyjaznedeklaracje/index/section-1_pl.mdx";
import IndexSection2PL from "../../content/przyjaznedeklaracje/index/section-2_pl.mdx";
import IndexSection3PL from "../../content/przyjaznedeklaracje/index/section-3_pl.mdx";

export default function HomeLayout(props) {
	const intl = useIntl();

	let Miasto = props.miasto;
	let Podatek = props.podatek;

	return (
		<>
			{/* HEAD */}
			<SEO
				miasto={Miasto}
				podatek={Podatek}
				noindex={Variables.miasta[Miasto].test}
				nofollow={Variables.miasta[Miasto].test}
			/>

			{/* TOP */}
			<Header miasto={Miasto} />

			<Slider miasto={Miasto} podatek={Podatek} typeHero="slider" />

			{/* SECTION */}

			<Section mdx>
				<IndexSection1PL />
			</Section>

			<Section title="sections.obslugiwanepodatki.title">
				<SectionBox light round section="obslugiwanepodatki" />
			</Section>

			<Section mdx>
				<IndexSection2PL />
			</Section>

			<Section title="sections.cozyskujesz.title">
				<SectionBox light section="cozyskujeszlight" />
			</Section>

			<Section mdx>
				<IndexSection3PL />
			</Section>

			{/* BOTTOM */}

			<Footer miasto={Miasto} />
		</>
	);
}
